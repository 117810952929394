import { ClientError } from '../../models/common';
import { Role } from '../../models/resource-manager';
import { SerializableMap } from '../../types/serializable-map';
import { UnionValueMap } from '../../types/union-map';
import { areStringsEquivalent, isUndefinedOrEmpty } from '../string';

type AzureActionTypeName = 'Read' | 'Write' | 'Delete' | 'Action';
type AzureActionType = 'read' | 'write' | 'delete' | 'action';

const AzureActionType: UnionValueMap<AzureActionTypeName, AzureActionType> = {
    Read: 'read',
    Write: 'write',
    Delete: 'delete',
    Action: 'action',
};

export type HasAccessSelector = (resourcePermissions: SerializableMap<Role[]>, resourceId: string) => boolean;

const doActionsIntersect = (actions: string[], otherActions: string[], caseInsensitive = false): boolean =>
    actions.some((action) =>
        otherActions.some((otherAction) => areStringsEquivalent(action, otherAction, caseInsensitive))
    );

export const doesRoleHaveAccess = (desiredActions: string[], actions: string[], notActions: string[]): boolean => {
    if (actions.length < 1) {
        return false;
    }

    // Not actions are used to allow a role to be configured that gives a user '*' permissions
    // while excluding specific actions (such as role assignments) from being allowed. As a result,
    // we check our not actions for any that match a possible action / permission that determines access and deny access
    // per role if there is a match.
    if (notActions.length > 0 && doActionsIntersect(desiredActions, notActions, true)) {
        return false;
    }

    return doActionsIntersect(desiredActions, actions, true);
};

const generateActionsForResource = (resourceType: string, actionType: AzureActionType, action?: string): string[] => {
    if (actionType === AzureActionType.Action && isUndefinedOrEmpty(action)) {
        throw new ClientError('When generating actions for an "action" ActionType, an action name must be provided.');
    }

    // global wildcard actions
    const actions = ['*', `*/${actionType}`];
    const typeParts = resourceType.split('/');
    const isActionType = actionType === AzureActionType.Action;

    // generate wildcard actions based on the resource type
    let actionPrefix = '';
    for (let i = 0; i < typeParts.length; ++i) {
        const typePart = typeParts[i];
        const isLastPart = i === typeParts.length - 1;

        actionPrefix = i === 0 ? typePart : `${actionPrefix}/${typePart}`;

        actions.push(`${actionPrefix}/*`);

        // special handling for the last part of our resource type
        if (isLastPart) {
            if (isActionType) {
                actions.push(`${actionPrefix}/*/${actionType}`);
                actions.push(`${actionPrefix}/${action}/${actionType}`);
            } else {
                actions.push(`${actionPrefix}/${actionType}`);
            }
        } else {
            actions.push(`${actionPrefix}/*/${actionType}`);
        }
    }

    return actions;
};

export const generateReadActionsForResource = (resourceType: string): string[] =>
    generateActionsForResource(resourceType, AzureActionType.Read);

export const generateWriteActionsForResource = (resourceType: string): string[] =>
    generateActionsForResource(resourceType, AzureActionType.Write);

export const generateDeleteActionsForResource = (resourceType: string): string[] =>
    generateActionsForResource(resourceType, AzureActionType.Delete);

export const generateActionActionsForResource = (resourceType: string, action: string): string[] =>
    generateActionsForResource(resourceType, AzureActionType.Action, action);
