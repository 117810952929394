import { DevBoxActions, EnvironmentAction, FullResourceType } from '../../../../constants/azure';
import { ProjectEnvironmentTypePermissionRecord, ProjectPermissionRecord } from '../../../../models/permission';
import { Role } from '../../../../models/resource-manager';
import {
    doesRoleHaveAccess,
    generateActionActionsForResource,
    generateReadActionsForResource,
} from '../../../../utilities/resource-manager/actions';

const devBoxReadActions = generateActionActionsForResource(FullResourceType.DevBoxes, DevBoxActions.UserRead);
const devBoxWriteActions = generateActionActionsForResource(FullResourceType.DevBoxes, DevBoxActions.UserWrite);
const devBoxCustomizeActions = generateActionActionsForResource(FullResourceType.DevBoxes, DevBoxActions.UserCustomize);
const environmentReadActions = [
    ...generateActionActionsForResource(FullResourceType.Environments, EnvironmentAction.AdminRead),
    ...generateActionActionsForResource(FullResourceType.Environments, EnvironmentAction.UserRead),
];
const environmentWriteActions = generateActionActionsForResource(
    FullResourceType.Environments,
    EnvironmentAction.UserWrite
);
const projectEnvironmentTypeReadActions = generateReadActionsForResource(FullResourceType.ProjectEnvironmentTypes);

const getProjectPermissionsFromRoles = (roles: Role[]): ProjectPermissionRecord => {
    let hasDevBoxReadPermission = false;
    let hasDevBoxWritePermission = false;
    let hasDevBoxCustomizePermission = false;
    let hasProjectEnvironmentTypeReadPermission = false;
    let hasEnvironmentReadPermission = false;
    let hasEnvironmentWritePermission = false;

    for (const role of roles) {
        if (
            hasDevBoxReadPermission &&
            hasDevBoxWritePermission &&
            hasDevBoxCustomizePermission &&
            hasEnvironmentReadPermission &&
            hasEnvironmentWritePermission &&
            hasProjectEnvironmentTypeReadPermission
        ) {
            break;
        }

        if (!hasDevBoxReadPermission && doesRoleHaveAccess(devBoxReadActions, role.dataActions, role.notDataActions)) {
            hasDevBoxReadPermission = true;
        }

        if (
            !hasDevBoxWritePermission &&
            doesRoleHaveAccess(devBoxWriteActions, role.dataActions, role.notDataActions)
        ) {
            hasDevBoxWritePermission = true;
        }

        if (
            !hasDevBoxCustomizePermission &&
            doesRoleHaveAccess(devBoxCustomizeActions, role.dataActions, role.notDataActions)
        ) {
            hasDevBoxCustomizePermission = true;
        }

        if (
            !hasProjectEnvironmentTypeReadPermission &&
            doesRoleHaveAccess(projectEnvironmentTypeReadActions, role.actions, role.notActions)
        ) {
            hasProjectEnvironmentTypeReadPermission = true;
        }

        if (
            !hasEnvironmentReadPermission &&
            doesRoleHaveAccess(environmentReadActions, role.dataActions, role.notDataActions)
        ) {
            hasEnvironmentReadPermission = true;
        }

        if (
            !hasEnvironmentWritePermission &&
            doesRoleHaveAccess(environmentWriteActions, role.dataActions, role.notDataActions)
        ) {
            hasEnvironmentWritePermission = true;
        }
    }

    return {
        hasDevBoxReadPermission,
        hasDevBoxWritePermission,
        hasDevBoxCustomizePermission,
        hasEnvironmentReadPermission,
        hasEnvironmentWritePermission,
        hasProjectEnvironmentTypeReadPermission,
    };
};

const getProjectEnvironmentTypePermissionsFromRoles = (roles: Role[]): ProjectEnvironmentTypePermissionRecord => {
    let hasEnvironmentReadPermission = false;
    let hasEnvironmentWritePermission = false;

    for (const role of roles) {
        if (hasEnvironmentReadPermission && hasEnvironmentWritePermission) {
            break;
        }

        if (
            !hasEnvironmentReadPermission &&
            doesRoleHaveAccess(environmentReadActions, role.dataActions, role.notDataActions)
        ) {
            hasEnvironmentReadPermission = true;
        }

        if (
            !hasEnvironmentWritePermission &&
            doesRoleHaveAccess(environmentWriteActions, role.dataActions, role.notDataActions)
        ) {
            hasEnvironmentWritePermission = true;
        }
    }

    return {
        hasEnvironmentWritePermission,
        hasEnvironmentReadPermission,
    };
};

export const processProjectPermissions = (results: Role[][]): ProjectPermissionRecord[] =>
    results.map((roles) => getProjectPermissionsFromRoles(roles));

export const processProjectEnvironmentTypePermissions = (results: Role[][]): ProjectEnvironmentTypePermissionRecord[] =>
    results.map((roles) => getProjectEnvironmentTypePermissionsFromRoles(roles));
